import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FirebaseRepository from '../service/firebase_repository';
import { firebaseApp } from '../service/firebase_config';
import AuthService from "../service/auth_service";

const firebaseRepository = new FirebaseRepository(firebaseApp);
const authService = new AuthService(firebaseApp);

const initialState = {
  loading: true,
  challengeSettingList: [],
  signState: null,
};

/* DB */
export const updateData = createAsyncThunk(
  'firebase/update',
  async ({ email, list }) => {
    console.log('updateData', email)
    await firebaseRepository.updateData(email, list)
  }
)

export const readData = createAsyncThunk(
  'firebase/read',
  async ({ email }) => {
    console.log('readData', email)
    const response = await firebaseRepository.readData(email)
    return response
  }
)

export const setChallengeSetting = createAsyncThunk(
  'firebase/ChallengeSetting/set',
  async ({ data }) => {
    console.log('setChallengeSetting', data)
    const response = await firebaseRepository.setChallengeSetting(data)
    return response
  }
)

export const getChallengeSetting = createAsyncThunk(
  'firebase/ChallengeSetting/get',
  async () => {
    console.log('firebase/ChallengeSetting/get')
    const response = await firebaseRepository.getChallengeSetting()
    return response
  }
)

export const getRole = createAsyncThunk(
  'firebase/getRole/get',
  async () => {
    console.log('firebase/getRole/get')
    const response = await firebaseRepository.getRole()
    return response
  }
)

//signInCheck 코드
export const signInCheck = createAsyncThunk(
  'firebase/signInState/signInCheck',
  async (none, { dispatch }) => {
    console.log('signInCheck')
    const response = authService.onAuthChange()
    // if (response) {
    //   dispatch(getRole(response.uid))
    // }
    console.log(response)
    return response
  }
)
export const signIn = createAsyncThunk(
  'firebase/signInState',
  async (none, { dispatch }) => {
    const response = await authService.login('Google').then(data => {
      console.log("google", data.user)
      return data
    })
    if (response) {
      dispatch(getRole(response.uid))
    }
    return response
  }
)

export const signOut = createAsyncThunk(
  'firebase/signInState/logout',
  async () => {
    authService.logout()
  }
)

export const firebaseRTK = createSlice({
  name: 'firebase',
  initialState,
  reducers: {
    setLogData: (state) => {
      console.log('setLog')
    },
  },
  extraReducers: {
    [updateData.fulfilled.type]: (state) => {
      console.log('updateData', state)
    },
    [readData.pending.type]: (state) => {
      state.loading = true;
    },
    [readData.fulfilled.type]: (state, action) => {
      console.log('readData', action, action.payload)
      state.list = action.payload
      state.loading = false;
    },
    [setChallengeSetting.fulfilled.type]: (state, action) => {
      console.log('setChallengeSetting', state)
      if (!(action.payload === false)) {
        console.log(action.payload)
        state.challengeSettingList.push(action.payload)
      }
    },
    [getChallengeSetting.pending.type]: (state) => {
      state.loading = true;
    },
    [getChallengeSetting.fulfilled.type]: (state, action) => {
      console.log('challengeSettingList', action, action.payload)
      state.challengeSettingList = action.payload
      state.loading = false;
    },
    //sign
    [signIn.fulfilled.type]: (state, action) => {
      state.signState = action.payload.user.email
    },
    [signOut.fulfilled.type]: (state, action) => {
    },
    [signInCheck.fulfilled.type]: (state, action) => {
      console.log('console.log(action.payload)')
      console.log(action.payload)
    },
  }
})

export const { setLogData } = firebaseRTK.actions

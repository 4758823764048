import style from "./App.module.css"
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HashRouter, Route, Routes } from 'react-router-dom';
import BoardPage from './pages/BoardPage';
import ResultPage from "./pages/ResultPage";
import MakerPage from "./pages/MakerPage";
import Sidebar from './components/Sidebar/Sidebar';
import Header from './components/Header/Header';
import Login from "./components/login/login";
const App = () => {
  const signState = useSelector((state) => state.firebase.signState);
  console.log('singState', signState, signState)
  // useEffect(() => {
  //   return () => {
  //     localStorage.clear();
  //   }
  // }, [])

  return (
    <div className={style.app} >
      {signState == null ?
        <Login /> :
        <HashRouter >
          <Sidebar />
          <div className="relative md:ml-64">
            <Header />
            <div className="mx-auto w-full h-full">
              <Routes>
                <Route path="/" element={<MakerPage />} />
                <Route path="/mypage" element={<ResultPage />} />
                <Route path="/maker" element={<MakerPage />} />
              </Routes>
            </div>
          </div>
        </HashRouter >}
    </div >
  );
}



export default React.memo(App);

import React from 'react';
import { useDispatch } from 'react-redux';
import { signStateUpdate, signIn } from '../../modules/firebase';

const Login = () => {
  const dispatch = useDispatch();
  const handlerSignIn = () => {
    dispatch(signIn())
  }

  return (
    <>
      <div className="h-full w-full bg-blueGray-500">
        <div className="flex content-center items-center justify-center h-full min-h-screen min-w-screen ">
          <div className="container mx-auto px-4">
            <div className=" mx-auto w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign in with
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    <button
                      className="bg-white active:bg-blueGray-50 text-blueGray-700 px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                      type="button"
                      onClick={handlerSignIn}
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src={require("../../assets/img/google.svg").default}
                      />
                      Google
                    </button>
                  </div>
                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                  <div className="text-blueGray-400 text-center mb-3 font-bold">
                    <small>@ver1.0 </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import { getFirestore, getDoc, setDoc, doc, collection, getDocs, query, Timestamp } from "firebase/firestore";

class FirebaseRepository {
  constructor(app) {
    this.store = getFirestore(app);
  }

  async updateData(email, list) {
    console.log(email, list)
    try {
      await setDoc(doc(this.store, "2022PIA", email), { list: list })
      console.log("Document written", list);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  async readData(email) {
    const challengerRef = doc(this.store, "2022PIA", email);
    const challengerSnapshot = await getDoc(challengerRef);
    console.log('challengerSnapshot:')
    console.log(challengerSnapshot.id, challengerSnapshot.data().list)

    return challengerSnapshot.data().list
  }


  async setChallengeSetting(data) {
    const ref = doc(collection(this.store, "challengeSetting"));
    const now = new Date();

    data.createdAt = Timestamp.fromDate(now);
    console.log(data)
    return await setDoc(ref, data).then(async () => {
      data.id = ref.id
      console.log('Document successfully written!', data);
      return data;
    }).catch((error) => {
      console.error("Error writing document: ", error);
      return false
    })
  }

  async getChallengeSetting() {
    var ref = query(collection(this.store, "challengeSetting"));
    console.log(ref)

    const challengeSettingSnapshot = await getDocs(ref);

    console.log(challengeSettingSnapshot)
    var challengeSettingList = [];
    challengeSettingSnapshot.forEach((doc) => {
      let docData = doc.data()
      docData.id = doc.id
      challengeSettingList.push(docData)
      console.log("challengeSettingList", doc.id, " => ", doc.data());
    });

    return challengeSettingList
  }

  async getRole(uid) {
    const roleRef = doc(this.store, "admin", uid);
    const roleSnapshot = await getDoc(roleRef);
    console.log('roleSnapshot:')
    console.log(roleSnapshot.id, roleSnapshot.data())

    return roleSnapshot.data()
  }
}

export default FirebaseRepository;

import React from "react"

const CircleCheckBox = ({ title, onClick, checked, disable = true, mainColor, subColor }) => {
  const checkIcon = <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 sm:h-6 sm:w-6 text-main_dark" style={{color:`${subColor}`}} fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
  </svg>

  const checkCircle = <div className='w-6 h-6 sm:w-8 sm:h-8 my-2 border-2 rounded-full flex justify-center' style={{borderColor:`${mainColor}`, backgroundColor:`${mainColor}80`}}>{checkIcon}</div>
  const unCheckCircle = <div className='w-6 h-6 sm:w-8 sm:h-8 my-2 border-2 rounded-full bg-white flex justify-center' style={{borderColor:`${mainColor}`}}>{ }</div>
  const disCheckCircle = <div className='w-6 h-6 sm:w-8 sm:h-8 my-2 border-2 rounded-full border-black/40 bg-black/30 flex justify-center'>{ }</div>

  return (
    <>
      {disable ?
        <label className='flex flex-col justify-center items-center' onClick={onClick}>
          <span className='text-black/60 w-8 text-xs sm:text-sm text-center'>{title}</span>
          {checked ? checkCircle : unCheckCircle}
        </label>
        :
        <label className='flex flex-col justify-center items-center'>
          <span className='text-black/60 w-8 text-xs sm:text-sm text-center'>{title}</span>
          {disCheckCircle}
        </label>
      }
    </>
  )
}

export default React.memo(CircleCheckBox);

import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getChallengeSetting, setChallengeSetting } from "../modules/firebase";
import { useState } from "react";
import CheckBox from "../components/Atom/CheckBox";

const MakerPage = () => {
  const dispatch = useDispatch()
  const fbState = useSelector((state) => state.firebase);
  const [mainColor, setMainColor] = useState('#ab8742');
  const [subColor, setSubColor] = useState('#561111');
  const [bgColor, setBgColor] = useState('#F0F0F0');
  const [title, setTitle] = useState('');
  const [period, setPeriod] = useState(21);
  const [col, setCol] = useState(7);
  const [target, setTarget] = useState('xingmove');

  const handleMainColorChange = (e) => {
    setMainColor(e.target.value);
  };
  const handleSubColorChange = (e) => {
    setSubColor(e.target.value);
  };
  const handleBgColorChange = (e) => {
    setBgColor(e.target.value);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handlePeriodChange = (e) => {
    setPeriod(e.target.value);
  };
  const handleColChange = (e) => {
    setCol(e.target.value);
  };
  const handleTargetChange = (e) => {
    console.log(e.target.value)
    setTarget(e.target.value);
  };

  const handleCreateChallenge = () => {
    if (title.length<=0){
      alert("타이틀이 비었습니다.");  // 복사 완료 알림
      return
    }
    
    const data = {
      "title" : title,
      "mainColor" : mainColor,
      "subColor" : subColor,
      "bgColor" : bgColor,
      "period" : period,
      "col" : col,
      "col" : col,
      "target" : target,
      "state" : 1
    }
    dispatch(setChallengeSetting({data:data}))
  };

  const handleCopyCode = (val) => {
    navigator.clipboard.writeText(
      `
      <style>
        .container {
          position: relative;
          width: 100%;
          height: 400px; 
          font-size: 16px;
        }
        .view {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 400px;
          font-size: 16px;
        }
      
      </style>
      
      <div id='container'>
        <iframe id='view' allowfullscreen frameborder="0"></iframe>
      </div>
      
      <script>
        window.onload = function()  {
          let email = undefined 
          try {
            email = document.getElementsByClassName("email-info")[0].childNodes[0].data.trim()
          } catch (error) {
            email = undefined
          }
          
          document.getElementById("container").className = "container"
          document.getElementById("view").className = "view"
          if(email != undefined){
            document.getElementById("view").src = "https://tracker.xingmove.com/#/challenge/?id=${val.id}&target=${val.target}&email=" + email 
          }else{
            document.getElementById("view").src = "https://tracker.xingmove.com/#/challenge/"
          }
        }
      </script>
      `
    )  // 클립보드에 내용 복사
      .then(function() {
        alert("내용이 복사되었습니다.");  // 복사 완료 알림
      })
      .catch(function(error) {
        console.error("내용을 복사하는 중 오류가 발생했습니다.", error);
      });
  };

  useEffect(()=>{
    dispatch(getChallengeSetting())
  },[])

  return (
    <>
      <div className="flex flex-col bg-slate-100 p-4">
        <div className="grid grid-cols-2">
          <span className="pl-10 text-left w-full border-r font-semibold text-gray-700">Title</span>
          <input className="ml-2 border w-28 text-gray-700 font-semibold rounded" type="text" defaultValue={title} onChange={handleTitleChange} />
        </div>
        <div className="grid grid-cols-2">
          <span className="pl-10 text-left w-full border-r font-semibold text-gray-700">MainColor</span>
          <input className="ml-2 border w-28 text-gray-700 font-semibold rounded" type="color" defaultValue={mainColor} onChange={handleMainColorChange} />
        </div>
        <div className="grid grid-cols-2">
          <span className="pl-10 text-left w-full border-r font-semibold text-gray-700">SubColor</span>
          <input className="ml-2 border w-28 text-gray-700 font-semibold rounded" type="color" defaultValue={subColor} onChange={handleSubColorChange} />
        </div>
        <div className="grid grid-cols-2">
          <span className="pl-10 text-left w-full border-r font-semibold text-gray-700">BgColor</span>
          <input className="ml-2 border w-28 text-gray-700 font-semibold rounded" type="color" defaultValue={bgColor} onChange={handleBgColorChange} />
        </div>
        <div className="grid grid-cols-2">
          <span className="pl-10 text-left w-full border-r font-semibold text-gray-700">Period</span>
          <input className="ml-2 border w-28 text-gray-700 font-semibold rounded" type="text" defaultValue={period} onChange={handlePeriodChange} />
        </div>
        <div className="grid grid-cols-2">
          <span className="pl-10 text-left w-full border-r font-semibold text-gray-700">Col</span>
          <input className="ml-2 border w-28 text-gray-700 font-semibold rounded" type="text" defaultValue={col} onChange={handleColChange} />
        </div>
        <div className="grid grid-cols-2">
          <span className="pl-10 text-left w-full border-r font-semibold text-gray-700">Target</span>
          <div className="ml-2 border w-28 text-gray-700 font-semibold rounded text-left">
            <div>
              <input type='radio' name='target' value='xingmove' onClick={handleTargetChange}/> XINGMOVE
            </div>
            <div>
              <input type='radio' name='target' value='dokavi' onClick={handleTargetChange}/> DOKAVI
            </div>
          </div>
        </div>
        <button className="ml-10 mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-24"
        onClick={handleCreateChallenge}>
          생성
        </button>
      </div>
      <div className="relative overflow-hidden mb-8">
        <div className="rounded-lg overflow-hidden border border-gray-400 p-10 flex flex-col justify-center m-4">
          <span className="uppercase font font-semibold text-gray-700">
            example
          </span>
          <div className="max-w-[1000px] ">
            <div className='flex flex-col rounded-md m-[0.1rem] max-w-screen-2xl uppercase font-bold px-4' style={{backgroundColor : `${bgColor}` }}>
              <div className='px-[5%] '>
                <div className="flex flex-row">
                  <div className="text-black/60 basis-1/2 text-left mx-2">Challenge Tracker</div>
                  <div className="text-black/60 basis-1/2 text-right mx-2">{Math.round((1 / 21) * 100)}%</div>
                </div>
                <div className="w-full bg-gray-300 rounded-full h-4 mb-6">
                  <div className={`h-4 rounded-full duration-75`} style={{ width: `${(1 / 21) * 100}%`, backgroundColor : `${mainColor}` }} />
                </div>
              </div>
              <div>
                <div className={`grid`}  style={{ gridTemplateColumns : ` repeat(${col}, minmax(0, 1fr))` }} >
                  {Array.from({ length: period }).map((_, index) => (
                    <CheckBox title={`${index+1}Day`} checked={1} mainColor={mainColor} subColor={subColor}/>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    <div className="px-8 py-2 w-full">
      <div className="shadow-md sm:rounded-lg w-full">
        <table className="w-full text-xs text-center text-gray-500 table-fixed">
          <thead className=" text-gray-700 bg-gray-50">
            <th scope="col" className="px-2 lg:px-4 py-3">
              Title
            </th>
            <th scope="col" className="px-2 lg:px-4 py-3">
              MainColor
            </th>
            <th scope="col" className="px-2 lg:px-4 py-3">
              SubCoor
            </th>
            <th scope="col" className="px-2 lg:px-4 py-3">
              BgColor
            </th>
            <th scope="col" className="px-2 lg:px-4 py-3">
              Period
            </th>
            <th scope="col" className="px-2 lg:px-4 py-3">
              Col
            </th>
            <th scope="col" className="px-2 lg:px-4 py-3">
              Target
            </th>
            <th scope="col" className="px-2 lg:px-4 py-3">
              Code
            </th>
          </thead>
          <tbody>
            {Array.isArray(fbState.challengeSettingList) && !fbState.loading ?
              fbState.challengeSettingList.map((val, idx) => {
                console.log("index:",idx,val)
                return (
                  <tr className="text-gray-700 border-t">
                    <th className="text-center text-gray-900 left-0 border-r">
                      {val.title}
                    </th>
                    <th className="text-center text-gray-900 left-0 border-r">
                      <button className="w-3 h-2 m-0 p-0 text-transparent" style={{backgroundColor :`${val.mainColor}`}}/>
                      {val.mainColor}
                    </th>
                    <th className="text-center text-gray-900 left-0 border-r">
                      <button className="w-3 h-2 m-0 p-0 text-transparent" style={{backgroundColor :`${val.subColor}`}}/>
                      {val.subColor}
                    </th>
                    <th className="text-center text-gray-900 left-0 border-r">
                      <button className="w-3 h-2 m-0 p-0 text-transparent" style={{backgroundColor :`${val.bgColor}`}}/>
                      {val.bgColor}
                    </th>
                    <th className="text-center text-gray-900 left-0 border-r">
                      {val.period}
                    </th>
                    <th className="text-center text-gray-900 left-0 border-r">
                      {val.col}
                    </th>
                    <th className="text-center text-gray-900 left-0 border-r">
                      {val.target}
                    </th>
                    <th className="text-center text-gray-900 left-0 border-r">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      onClick={()=>{handleCopyCode(val)}}>
                        복사
                      </button>
                      <span className="hidden"> {val.id}</span>
                    </th>
                  </tr>
                )
              }) : <></>
            }
          </tbody>
        </table>
      </div>
    </div>
    </> 
  );
}


export default React.memo(MakerPage);
